import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ClinicsList from '@app/components/manage-clinics/list';
import AddClinic from '@app/components/manage-clinics/add-clinic';
import { ValidationFormClinicAdd } from '@app/components/manage-clinics/ValidationFormAdd/ValidationForm';
import { Card, Row, Col, Image } from 'antd';
import loginBackground from '@app/assets/images/form-bg-image.jpg';
import { getStatesTreeAction } from '@app/store/slices/userSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
const ClinicsOnboardPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {

        dispatch(getStatesTreeAction())
            .unwrap()
            .then((resp) => {


            }).catch((err) => {
                notificationController.error({ message: err.message || 'Something went wrong' });
            })


    }, [])
    return (
        <div className='asm clinic-onboard-p' >
            <PageTitle>{'Clinic Onboard'}</PageTitle>
            <Row gutter={[0, 20]}>
                <Col sm={24} lg={12} className='form-bg-img'>
                    <img src={loginBackground} />
                    
                </Col>
                <Col  sm={24} lg={12}>
                    <Card title='Clinic Onboard' className="Onboard-form">
                        <ValidationFormClinicAdd outer={true} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ClinicsOnboardPage;
