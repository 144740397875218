import { css } from 'styled-components';


export const resetCss = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 1rem;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 1.25rem;
    border: 0.375rem solid transparent;
    background-clip: content-box;
  }

  body {
    font-weight: 500;
  }

  img {
    display: block;
  }
  #root {
    min-height: 100%;
  }

  .clinic-onboard-form {
    .ant-form-item-label { 
      label {
        height: auto;
      }
    }
  }

  .clinic-onboard-p {
    min-height: 100vh;
    &> .ant-row {
      position: relative;
      min-height: 100vh;
    }
    .form-bg-img {
      @media (max-width: 991px) {
        position: absolute;
        bottom: 0;
      }
      .ant-image {
        height: 100%;
        width:100%;
        position : relative;
        &::after {
          content: '';
          background: var(--black);
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          opacity: .6;
        }
        img {
          height: 100%;
          object-fit: cover;
        }
      }    
      &+ .ant-col {
        align-content: center;
      }  
    }
    .Onboard-form {
      border:0;
      box-shadow: 0px 0px 20px rgba(0,0,0,.1);
      @media (max-width: 991px) {
        width: 90%;
        margin: 50px auto 100px;
      }
      @media (min-width: 991px) {
        margin: 3rem 3rem 3rem -15rem;
      }      
      
      .ant-card-head {
        background: var(--green);
        color: var(--white);
      }
    }
    .ant-btn-primary {
      margin-top: 25px;
    }
    .ant-upload.ant-upload-select {
      width: 100%;
      .ant-upload {
        width: 100%;
      }
    }
    .ant-btn.ant-btn-default {
      background: var(--black);
      color: var(--white);
      width: 100%;
    }
  }

`;
