import React, { useEffect, useState, useCallback } from 'react';
import { BasicPatientRow, BasicTableRow, getPatientsData, Pagination } from 'api/clinicsGet.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useNavigate } from "react-router-dom";
import { getAllClientsAction } from '@app/store/slices/userSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import moment from 'moment';
import { readUser } from '@app/services/localStorage.service';
import { getClinicAppointmentsAction, getDoctorAppointmentsAction, martAppointmentAction } from '@app/store/slices/appointmentSlice';
import { BaseBadge } from '@app/components/common/BaseBadge/BaseBadge';
import config from '@app/config';
import Icon from '@ant-design/icons/lib/components/Icon';
import { CaretRightFilled, CheckCircleFilled, CheckOutlined, CloseCircleFilled, CloseOutlined, CopyFilled, DiffFilled, EditFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
const initialPagination: Pagination = {
  current: 1,
  pageSize: 30,
};

export const BasicTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: BasicPatientRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clinicDetails = readUser();
  useEffect(() => {
    initializer();

  }, []);


  const initializer = () => {

    if (clinicDetails?.role_id == 2) {
      dispatch(getDoctorAppointmentsAction(clinicDetails!.id))
        .unwrap()
        .then((resp: any) => {
          console.log({ resp });
          setTableData({ ...tableData, data: resp });

        }).catch((err) => {
          notificationController.error({ message: err.message });
        })
    }
    else {
      dispatch(getClinicAppointmentsAction(clinicDetails!.id))
        .unwrap()
        .then((resp: any) => {
          console.log({ resp });
          setTableData({ ...tableData, data: resp });

        }).catch((err) => {
          notificationController.error({ message: err.message });
        })
    }

  }

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      // getPatientsData(pagination).then((res) => {
      //   if (isMounted.current) {
      //     setTableData({ data: res.data, pagination: res.pagination, loading: false });
      //   }
      // });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };



  const handleGenerateLink = (id: number) => {
    navigator.clipboard.writeText(config.frontendURL + '/company/patients/edit/' + id);
    notificationController.success({ message: 'Patient Link Copied' });
  }

  const handleCreatePatient = (record: any) => {

    let args = {
      firstName: record.firstName,
      lastName: record.lastName,
      dob: moment(record.dob).format('MM-DD-YYYY'),
      gender: record.gender,
      phone: record.phone

    };

    navigate('/company/patients/add?data=' + encodeURIComponent(JSON.stringify(args)));;
  }

  const handleMarkAsComplete = (record: any, type: number) => {

    setTableData((tableData) => ({ ...tableData, loading: true }));
    dispatch(martAppointmentAction({ id: record!.id, payload: { status: type } }))
      .unwrap()
      .then((resp: any) => {
        setTableData((tableData) => ({ ...tableData, loading: false }));
        console.log({ resp });
        if (!resp.error && !resp.Error) {
          notificationController.success({ message: 'Appointment status updated' });
          initializer();
        }
        else {
          notificationController.warning({ message: 'Appointment status not updated' });
        }


      }).catch((err) => {
        setTableData((tableData) => ({ ...tableData, loading: false }));
        notificationController.error({ message: err.message });
      })
  }


  const columns: ColumnsType<BasicTableRow> = [
    {
      title: 'First Name',
      dataIndex: 'firstName'
    },
    // {
    //   title: t('common.age'),
    //   dataIndex: 'age',
    //   sorter: (a: BasicTableRow, b: BasicTableRow) => a.age - b.age,
    //   showSorterTooltip: false,
    // },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: "Date Of Birth",
      dataIndex: 'dob',
      render: (text: string, record: any) => {

        return <span>{text ? moment(text).format('DD-MM-YYYY') : ''}</span>;
      }
    },
    {
      title: "Mobile Number",
      dataIndex: 'phone',
    },
    {
      title: "Gender",
      dataIndex: 'gender',
    },

    {
      title: "Appointment Date",
      dataIndex: 'date',
    },
    {
      title: "Appointment Time",
      dataIndex: 'time',
    },
    {
      title: "Appointment Status",
      dataIndex: 'status',
      render: (text: string, record: any) => {

        return <span>{record.status == 2 ? <BaseBadge status="success" text="Completed" /> : record.status == 1 ? <BaseBadge status="error" text="Cancelled" /> : (record.status == 0 || !record.status) ? <BaseBadge status="warning" text="Pending" /> : ""}</span>;
      }
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: any) => {
        return (
          <BaseSpace>
            <Tooltip
              title="Edit">
              <EditFilled
                style={{ fontSize: '24px' }}
                onClick={() => {
                  navigate('/company/appointments/edit/' + record.id)
                  // notificationController.info({ message: t('tables.inviteMessage', { name: record.name }) });
                }}
              />
            </Tooltip>


            {/* {record.usersAppointment ?
              <Tooltip
                title="Go to patient">
                <CaretRightFilled
                  style={{ fontSize: '24px' }}
                  onClick={() => navigate('/company/patients/edit/' + record?.usersAppointment?.id + '?doctor_id=' + record.doctor_id)}
                />
              </Tooltip>
              :
              <Tooltip
                title="Create Patient">
                <DiffFilled
                  style={{ fontSize: '24px' }}
                  onClick={() => handleCreatePatient(record)}
                />
              </Tooltip>

            } */}
            <Tooltip
              title="Mark as complete">
              <CheckCircleFilled
                style={{ fontSize: '24px' }}
                onClick={() => handleMarkAsComplete(record, 2)}
              />
            </Tooltip>
            <Tooltip title="Mark as cancelled">
              <CloseCircleFilled
                style={{ fontSize: '24px' }}
                onClick={() => handleMarkAsComplete(record, 1)}
              />
            </Tooltip>
            {/* <Tooltip title="Generate Patient Link">
              <CopyFilled
                style={!record.usersAppointment ? { cursor: 'not-allowed', opacity: '0.5', fontSize: '24px' } : { fontSize: '24px' }}
                onClick={() => record.usersAppointment ? handleGenerateLink(record && record.usersAppointment && record.usersAppointment.id) : {}}
              />
            </Tooltip> */}
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={tableData.pagination}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
      bordered
    />
  );
};
